.footerContainer {
    background: #fff;
    color: #000;
    padding: 40px 0;
    border-top: 1px #000 solid;
}

.title {
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
}

.footerNav {
    font-size: 14px;
    font-weight: 800;
    flex: 1;
    text-transform: uppercase;
}

.footerLinks {
}
