@font-face {
  font-family: "SoehneBreit";
  src: url("/fonts/soehne/soehne-breit-buch.woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
    font-family: "SoehneBreit";
    src: url("/fonts/soehne/soehne-breit-dreiviertelfett.woff2");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "SoehneBreit";
    src: url("/fonts/soehne/soehne-breit-extrafett.woff2");
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: "SoehneBreit";
    src: url("/fonts/soehne/soehne-breit-fett.woff2");
    font-style: normal;
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: "SoehneBreit";
    src: url("/fonts/soehne/soehne-breit-halbfett.woff2");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "SoehneBreit";
    src: url("/fonts/soehne/soehne-breit-kraftig.woff2");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "SoehneBreit";
    src: url("/fonts/soehne/soehne-breit-leicht.woff2");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
    margin: 0 auto;
    color: #FFFFFFFF;
    background: #000000FF;
}

body, h1, h2, h3, h4, h5, h6 {
    font-family: 'SoehneBreit', serif;
}

a {
    text-decoration: none;
    color: inherit;
}
