.container {
    position: absolute;
    background: black;
    min-height: 10%;
    width: 100%;
    top: 0;
    left: 0;
}

.title {
    font-size: 140%;
    font-family: 'SoehneBreit', sans-serif;
    font-weight: 800;
}

.exploitedHamburger {
    max-width: 50%;
}

.hamburger {
    margin-top: 1%;
    width: fit-content;
    position: absolute;
    right: 0%;
    top: 0%;
}
